<!-- 用户管理右侧界面 -->
<template>
  <div>
    <div style="margin-bottom: 5px">
      <el-input
        v-model="name"
        placeholder="请输入名字"
        suffix-icon="el-icon-search"
        style="width: 200px"
        @keyup.enter.native="loadPost"
      ></el-input>
      <el-select
        v-model="sex"
        filterable
        placeholder="请选择性别"
        style="margin-left: 5px"
      >
        <el-option
          v-for="item in sexs"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
      <el-button type="primary" style="margin-left: 5px" @click="loadPost"
        >查询</el-button
      >
      <el-button type="success" @click="resetParam">重置</el-button>

      <!-- <el-button type="primary" style="margin-left: 5px" @click="add"
        >新增</el-button
      > -->
    </div>
    <el-table
      :data="tableData"
      :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
      border
    >
      <el-table-column prop="id" label="ID" width="60"> </el-table-column>
      <el-table-column prop="head" label="用户头像" width="100">
        <template slot-scope="scope">
          <img
            style="width: 50px; height: 50px"
            :src="$httpUrl + '/image/' + scope.row.head"
            alt=""
          />
        </template>
      </el-table-column>
      <el-table-column prop="name" label="姓名" width="100"> </el-table-column>
      <el-table-column prop="phone" label="手机号" width="180">
      </el-table-column>
      <el-table-column prop="email" label="邮箱" width="180"> </el-table-column>
      <!-- <el-table-column prop="business" label="入驻" width="70">
      </el-table-column>
      <el-table-column prop="s_name" label="店铺" width="180">
      </el-table-column> -->
      <el-table-column prop="sex" label="性别" width="70">
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.sex == 1 ? 'primary' : 'success'"
            disable-transitions
            >{{ scope.row.sex == 1 ? "男" : "女" }}</el-tag
          >
        </template>
        >
      </el-table-column>
      <el-table-column prop="operate" label="操作">
        <template slot-scope="scope">
          <!-- <el-button size="small" type="success" @click="mod(scope.row)"
            >重置</el-button
          > -->
          <el-popconfirm
            title="确定删除吗？"
            @confirm="del(scope.row.id)"
            style="margin-left: 5px"
          >
            <el-button slot="reference" size="small" type="danger"
              >删除</el-button
            >
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageNum"
      :page-sizes="[5, 10, 20, 30]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <el-dialog
      title="提示"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <el-form ref="form" :rules="rules" :model="form" label-width="80px">
        <el-form-item label="账号" prop="no">
          <el-col :span="20">
            <el-input v-model="form.no"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="名字" prop="name">
          <el-col :span="20">
            <el-input v-model="form.name"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-col :span="20">
            <el-input v-model="form.password"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="用户头像" prop="head">
          <el-col :span="20">
            <imgUploader
              :iga="form.head"
              ref="pic2"
              :width="200"
              :height="200"
              :limit="1"
              @change="img()"
            >
            </imgUploader>
          </el-col>
        </el-form-item>
        <el-form-item label="年龄" prop="age">
          <el-col :span="20">
            <el-input v-model="form.age"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-radio-group v-model="form.sex">
            <el-radio label="1">男</el-radio>
            <el-radio label="0">女</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="电话" prop="phone">
          <el-col :span="20">
            <el-input v-model="form.phone"></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="save">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import imgUploader from "../imgUploader/imgUploader.vue";
export default {
  name: "UserManage",
  components: {
    imgUploader,
  },
  data() {
    let checkAge = (rule, value, callback) => {
      if (value > 150) {
        callback(new Error("年龄输入过大"));
      } else {
        callback();
      }
    };
    let checkDuplicate = (rule, value, callback) => {
      if (this.form.id) {
        return callback();
      }
    };

    return {
      tableData: [
        // {
        //   id: 1,
        //   name: "asd",
        //   phone: "123465489",
        //   sex: 0,
        //   email: "12311@qq.com",
        //   business: 1,
        //   s_name: "超市",
        //   head: "1677214553718.jpeg",
        //   s_id: "5",
        // },
        // {
        //   id: 1,
        //   name: "asd",
        //   phone: "123465489",
        //   sex: 0,
        //   email: "12311@qq.com",
        //   business: 1,
        //   s_name: "超市",
        //   head: "1677214553718.jpeg",
        //   s_id: "5",
        // },
        // {
        //   id: 1,
        //   name: "asd",
        //   phone: "123465489",
        //   sex: 0,
        //   email: "12311@qq.com",
        //   business: 1,
        //   s_name: "超市",
        //   head: "1677214553718.jpeg",
        //   s_id: "5",
        // },
        // {
        //   id: 1,
        //   name: "asd",
        //   phone: "123465489",
        //   sex: 0,
        //   email: "12311@qq.com",
        //   business: 1,
        //   s_name: "超市",
        //   head: "1677214553718.jpeg",
        //   s_id: "5",
        // },
      ],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      name: "",
      sex: "",
      sexs: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      centerDialogVisible: false,
      form: {
        id: "",
        no: "",
        name: "",
        password: "",
        age: "",
        phone: "",
        sex: 0,
        roleId: "2",
        head: [],
      },
      rules: {
        no: [
          { required: true, message: "请输入账号", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
          { validator: checkDuplicate, trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入名字", trigger: "blur" }],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 15,
            message: "长度在 6 到 15 个字符",
            trigger: "blur",
          },
        ],
        age: [
          { required: true, message: "请输入年龄", trigger: "blur" },
          { min: 1, max: 3, message: "长度在 1 到 3 个位", trigger: "blur" },
          {
            pattern: /^([1-9][0-9]*){1,3}$/,
            message: "年龄必须为正整数字",
            trigger: "blur",
          },
          { validator: checkAge, trigger: "blur" },
        ],
        head: [{ required: true, message: "请选择性别", trigger: "blur" }],
        sex: [{ required: true, message: "请选择性别", trigger: "blur" }],
        phone: [
          { required: true, message: "手机号不能为空", trigger: "blur" },
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    // 图片上传
    img() {
      let vm = this;
      let b = vm.$refs.pic2.imgUrl;
      this.form.head = b;
    },

    resetForm() {
      this.$refs.form.resetFields();
    },
    del(id) {
      this.$axios
        .get(this.$httpUrl + "/user/remove/" + id)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "删除成功！",
              type: "success",
            });
            this.loadPost();
          } else {
            this.$message({
              message: "删除失败！",
              type: "error",
            });
          }
        });
    },
    mod(row) {
      this.form.head = [];
      this.centerDialogVisible = true;

      //赋值到表单
      this.form.id = row.id;
      this.form.no = row.no;
      this.form.name = row.name;
      this.form.age = row.age + "";
      this.form.sex = row.sex == true ? "1" : "0";
      this.form.head.push({
        base64: this.$httpUrl + "/image/" + row.head,
      });
      this.form.phone = row.phone;
    },
    add() {
      this.centerDialogVisible = true;
      this.$nextTick(() => {
        this.form = {};
      });
    },
    doSave() {
      this.$axios
        .post(this.$httpUrl + "/user/save", this.form)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.centerDialogVisible = false;
            this.loadPost();
            this.resetForm();
          } else {
            this.$message({
              message: "操作失败！",
              type: "error",
            });
          }
        });
    },
    doMod() {
      this.$axios
        .post(this.$httpUrl + "/user/update", this.form)
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.centerDialogVisible = false;
            this.loadPost();
            this.resetForm();
          } else {
            this.$message({
              message: "操作失败！",
              type: "error",
            });
          }
        });
    },
    save() {
      let vm = this;
      let b = vm.$refs.pic2.imgUrl;
      this.form.head = b;
      if (this.form.sex == 1) {
        this.form.sex = true;
      } else if (this.form.sex == 0) {
        this.form.sex = false;
      }
      let a = {
        id: this.form.id,
        no: this.form.no,
        name: this.form.name,
        password: this.form.password,
        age: this.form.age,
        phone: this.form.phone,
        sex: this.form.sex,
        head: this.form.head[0].base64,
      };
      this.$axios
        .post(this.$httpUrl + "/user/add", a)
        .then((res) => res.data)
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            this.$message({
              message: "操作成功！",
              type: "success",
            });
            this.loadPost();
            this.centerDialogVisible = false;
          } else {
            this.$message({
              message: "添加失败！",
              type: "warning",
            });
          }
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageNum = 1;
      this.pageSize = val;
      this.loadPost();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.loadPost();
    },
    resetParam() {
      this.name = "";
      this.sex = "";
    },
    loadPost() {
      this.tableData = [];

      this.$axios
        .post(this.$httpUrl + "/user/pagelist", {
          id: 0,
          pageSize: this.pageSize,
          pageNum: this.pageNum,
          param: {
            name: this.name,
            sex: this.sex,
          },
          role: 0,
        })
        .then((res) => res.data)
        .then((res) => {
          if (res.code == 200) {
            this.total = res.data.length;
            this.tableData = res.data;
          } else {
            alert("获取数据失败");
          }
        });
    },
  },
  beforeMount() {
    this.loadPost();
  },
};
</script>

<style scoped>
</style>